.futura {
	font-family: futura-pt, sans-serif;
	font-weight: 700;
	font-style: normal;
}

.otrl-background--blue {
	background: #008ad8 !important;
}

.otrl-color--blue {
	color: #008ad8 !important;
}

.otrl-color--red {
	color: #e10600 !important;
}

.otrl-background--red {
	background: #e10600 !important;
}

.otrl-color--green {
	color: #77be20 !important;
}

.otrl-background--green {
	background: #77be20 !important;
}

.otrl-color--light-blue {
	color: #92c1e9 !important;
}

.otrl-background--light-blue {
	background: #92c1e9 !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
	margin: 0 !important;
}

.ant-table-thead > tr > th {
	background: #fafafa !important;
	border-bottom: 1px solid #e8e8e8 !important;
}
